<script>
import {
  authEasyMethods,
  notificationMethods
} from "@/state/helpers";


export default {
    data() {
        return {
            empresas: null,
            user: null,
			currentEmpresa: null
        }
    },
    computed: {
        notification() {
            return this.$store ? this.$store.state.notification : null;
        }
    },
    created() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.empresas = this.user.empresas;
    },
    methods: {
        ...authEasyMethods,
        ...notificationMethods,
        selected() {
          this.selectEmpresa(this.currentEmpresa);
          this.$router.push(
            this.$route.query.redirectFrom || { name: "home" }
          );
        }
    },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo.png" height="90" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Empresa</h4>
                        <p class="text-muted">Selecione uma empresa para continuar!</p>
                      </div>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="selected">
                          <div class="user-thumb text-center mb-5">
                            <img
                              src="@/assets/images/users/no-avatar.jpg"
                              class="rounded-circle img-thumbnail avatar-md"
                              alt="thumbnail"
                            />
                            <h5 class="font-size-15 mt-3">{{ user.username }}</h5>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-building-fill auti-custom-input-icon"></i>
                            <label for="userpassword">Empresa</label>
                            <select v-model="currentEmpresa"
                              class="form-control"
                              id="userpassword"
                            >
                                <option value="0" selected>Selecione</option>
                                <option v-for="empresa in empresas" :key="empresa.id" :value="empresa">{{ empresa.apelido }}</option>
                            </select>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >Selecionar</button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          © 2021 EasyIndustria Web 1.0. <a href="https://sidsolucoes.com">SiD Soluções</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>